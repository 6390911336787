import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//CAPA DE CATEGORÍAS DE PROTECCIÓN
//Función para capa de ENP
var styleFunction_ENP = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_ENP"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_ENP],
    fill: Funct_FillStyle[Symbolyze_Layer_I_ENP],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


var vectorSource_ENP = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ENP)
});

global.Layer_I_ENP = new VectorLayer({
  title: 'Layer_I_ENP',
  name: 'F01 ' + Symbolyze_Layer_I_ENP,
  type: 'Capa específica',
  source: vectorSource_ENP,
  style: styleFunction_ENP,
  zIndex:9
});



global.styleFunction_I_ZEC = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_ZEC"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_ZEC],
    fill: Funct_FillStyle[Symbolyze_Layer_I_ZEC],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


global.vectorSource_I_ZEC = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_ZEC)
});

global.Layer_I_ZEC = new VectorLayer({
  title: 'Layer_I_ZEC',
  name: 'F02 ' + Symbolyze_Layer_I_ZEC,
  type: 'Capa específica',
  source: vectorSource_I_ZEC,
  style: styleFunction_I_ZEC,
  zIndex:8
});



global.styleFunction_I_ZEPA = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_ZEPA"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_ZEPA],
    fill: Funct_FillStyle[Symbolyze_Layer_I_ZEPA],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


global.vectorSource_I_ZEPA = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_ZEPA)
});

global.Layer_I_ZEPA = new VectorLayer({
  title: 'Layer_I_ZEPA',
  name: 'F03 ' + Symbolyze_Layer_I_ZEPA,
  type: 'Capa específica',
  source: vectorSource_I_ZEPA,
  style: styleFunction_I_ZEPA,
  zIndex:8
});


global.styleFunction_I_Montes = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_Montes],
    fill: Funct_FillStyle[Symbolyze_Layer_I_Montes]
  })
};


global.vectorSource_I_Montes = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Montes_consorciados)
});

global.Layer_I_Montes = new VectorLayer({
  title: 'Layer_I_Montes',
  name: 'F04 ' + Symbolyze_Layer_I_Montes,
  type: 'Capa específica',
  source: vectorSource_I_Montes,
  style: styleFunction_I_Montes,
  zIndex:7
});


var styleFunction_I_HIC = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_HIC],
    fill: Funct_FillStyle[Symbolyze_Layer_I_HIC]
  })
};


var vectorSource_I_HIC = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_HIC)
});

global.Layer_I_HIC = new VectorLayer({
  title: 'Layer_I_HIC',
  name: 'F05 ' + Symbolyze_Layer_I_HIC,
  type: 'Capa específica',
  source: vectorSource_I_HIC,
  style: styleFunction_I_HIC,
  zIndex:7
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_01 = new LayerGroup({
  title:    'Map_IRJ_01',
  name:     'Categorías de protección',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_ENP,
    Layer_I_ZEC,
    Layer_I_ZEPA,
    Layer_I_Montes,
    Layer_I_HIC
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE AFECCIONES SECTORIALES

global.styleFunction_I_AFEC_CARR = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_AFEC_CARR],
    fill: Funct_FillStyle[Symbolyze_Layer_I_AFEC_CARR]
  })
};


global.vectorSource_I_AFEC_CARR = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Afeccion_carreteras)
});

global.Layer_I_AFEC_CARR = new VectorLayer({
  title: 'Layer_I_AFEC_CARR',
  name: 'F01 ' + Symbolyze_Layer_I_AFEC_CARR,
  type: 'Capa específica',
  source: vectorSource_I_AFEC_CARR,
  style: styleFunction_I_AFEC_CARR,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_02 = new LayerGroup({
  title:    'Map_IRJ_02',
  name:     'Afecciones sectoriales',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_AFEC_CARR
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CLASIFICACIÓN DE SUELO EN PLANEAMIENTO VIGENTE

var styleFunction_I_NNSS_Clasif = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_NNSS_Clasif"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().Clasificac],
    fill: Funct_FillStyle[feature.getProperties().Clasificac],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


var vectorSource_I_NNSS_Clasif = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_Clasificacion)
});

global.Layer_I_NNSS_Clasif = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif',
  name: '01 NNSS Clasificación',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif,
  style: styleFunction_I_NNSS_Clasif,
  zIndex:3
});


var GeoJSON_NNSS_Clasificacion_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_NNSS_Clasificacion.features.length; i++){
  var feature =GeoJSON_NNSS_Clasificacion.features[i];

  if (feature.properties["Categoriza"] === "Asentamiento Rural") {
    GeoJSON_NNSS_Clasificacion_Filter.features.push(feature);
  }
};

var vectorSource_I_NNSS_Clasif_AR = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_Clasificacion_Filter)
});


var styleFunction_I_NNSS_Clasif_AR = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_NNSS_Clasif_AR],
    fill: Funct_FillStyle[Symbolyze_Layer_I_NNSS_Clasif_AR]
  })
};

global.Layer_I_NNSS_Clasif_AR = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif_AR',
  name: 'F02 ' + Symbolyze_Layer_I_NNSS_Clasif_AR,
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif_AR,
  style: styleFunction_I_NNSS_Clasif_AR,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_03a = new LayerGroup({
  title:    'Map_IRJ_03a',
  name:     'Planeamiento Vigente. Clasificación del suelo',
  type:     'Capa específica',
  opacity:   0.50,
	visible:   false,
  layers:[
    Layer_I_NNSS_Clasif,
    Layer_I_NNSS_Clasif_AR
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CATEGORIZACIÓN DE SUELO EN PLANEAMIENTO VIGENTE

var styleFunction_I_NNSS_Categ = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_NNSS__Categ"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TxtCLA],
    fill: Funct_FillStyle[feature.getProperties().TxtCLA]
  })
};


var vectorSource_I_NNSS_Categ = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_Categ)
});

global.Layer_I_NNSS_Categ = new VectorLayer({
  title: 'Layer_I_NNSS_Categ',
  name: '01 NNSS Categorización del suelo',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Categ,
  style: styleFunction_I_NNSS_Categ,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_03b = new LayerGroup({
  title:    'Map_IRJ_03b',
  name:     'Planeamiento Vigente. Categorización del suelo',
  type:     'Capa específica',
  opacity:   0.50,
	visible:   false,
  layers:[
    Layer_I_NNSS_Categ
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ORDENACIÓN PORMENORIZADA DE SUELO EN PLANEAMIENTO VIGENTE
var vectorSource_NNSS_OP  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_OP)
});

var styleFunction_NNSS_OP  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_NNSS_OP[feature.getProperties().tPlanZUSO][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion +" OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"]
  })
};

global.Layer_I_NNSS_OP = new VectorLayer({
  title: 'Layer_I_NNSS_OP',
  name: '01 Ordenación Pormenorizada',
  type: 'Capa específica',
  source: vectorSource_NNSS_OP,
  style: styleFunction_NNSS_OP,
  zIndex:1
});

global.Map_IRJ_03c = new LayerGroup({
  title:         'Map_IRJ_03c',
  name:       'Planeamiento Vigente. Ordenación Pormenorizada',
  type:         'Capa específica',
  opacity:    1,
	visible:     false,
  layers:[
    Layer_I_NNSS_OP
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CLASIFICACIÓN DE SUELO EN PLANEAMIENTO VIGENTE

var styleFunction_PIO2023_zon = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_PIO2023_zon"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().GRU_VALOR],
    fill: Funct_FillStyle[feature.getProperties().GRU_VALOR],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};


var vectorSource_PIO2023_zon = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_PIO2023_zon)
});

global.Layer_PIO2023_zon = new VectorLayer({
  title: 'Layer_PIO2023_zon',
  name: '01 Zonificación',
  type: 'Capa específica',
  source: vectorSource_PIO2023_zon,
  style: styleFunction_PIO2023_zon,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_04 = new LayerGroup({
  title:    'Map_IRJ_04',
  name:     'Planeamiento Insular. Zonificación PIOGC 2023',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_PIO2023_zon
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ESPECIES PROTEGIDAS FAUNA

var styleFunction_ESP_PROT_FAUNA = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_ESP_PROT[feature.getProperties().Catálogo_][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion],
    zIndex: Funct_ZIndexStyle[ValorSimbolizacion]
  })
};

var GeoJSON_ESP_PROT_FAUNA_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_ESP_PROT_Fauna.features.length; i++){
  var feature =GeoJSON_ESP_PROT_Fauna.features[i];

  if ((feature.properties["Catálogo_"] != null)) {
    GeoJSON_ESP_PROT_FAUNA_Filter.features.push(feature);
  }
};

var vectorSource_ESP_PROT_Fauna = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ESP_PROT_FAUNA_Filter)
});

global.Layer_ESP_PROT_FAUNA = new VectorLayer({
  title:  'Layer_ESP_PROT_FAUNA',
  name:   '01 Ley 4/2010, de 4 de junio, del Catálogo Canario de Especies Protegidas',
  type:   'Capa específica',
  source: vectorSource_ESP_PROT_Fauna,
  style:  styleFunction_ESP_PROT_FAUNA
});


//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_09a = new LayerGroup({
  title:    'Map_IRJ_09a',
  name:     'Especies protegidas. Fauna',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_ESP_PROT_FAUNA
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ESPECIES PROTEGIDAS FLORA

var styleFunction_ESP_PROT_FLORA = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_ESP_PROT[feature.getProperties().Catálogo_][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion],
    zIndex: Funct_ZIndexStyle[ValorSimbolizacion]
  })
};

var GeoJSON_ESP_PROT_FLORA_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_ESP_PROT_Flora.features.length; i++){
  var feature =GeoJSON_ESP_PROT_Flora.features[i];

  if ((feature.properties["Catálogo_"] != null)) {
    GeoJSON_ESP_PROT_FLORA_Filter.features.push(feature);
  }
};

var vectorSource_ESP_PROT_FLORA = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ESP_PROT_FLORA_Filter)
});

global.Layer_ESP_PROT_FLORA = new VectorLayer({
  title:  'Layer_ESP_PROT_FLORA',
  name:   '01 Ley 4/2010, de 4 de junio, del Catálogo Canario de Especies Protegidas',
  type:   'Capa específica',
  source: vectorSource_ESP_PROT_FLORA,
  style:  styleFunction_ESP_PROT_FLORA
});


//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_09b = new LayerGroup({
  title:    'Map_IRJ_09b',
  name:     'Especies protegidas. Flora',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_ESP_PROT_FLORA
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ESPECIES INVASORAS

var styleFunction_ESP_INV = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_I_ESP_INV[feature.getProperties().RIQUEZA][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion],
  })
};


var vectorSource_ESP_INV = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ESP_INV)
});

global.Layer_ESP_INV = new VectorLayer({
  title: 'Layer_ESP_INV',
  name: '01 Número de especies invasoras',
  type: 'Capa específica',
  source: vectorSource_ESP_INV,
  style: styleFunction_ESP_INV,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_09c = new LayerGroup({
  title:    'Map_IRJ_09c',
  name:     'Especies invasoras',
  type:     'Capa específica',
  opacity:   0.5,
	visible:   false,
  layers:[
    Layer_ESP_INV
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE PATRIMONIO CULTURAL

var styleFunction_I_PC_Arqui_NNSS  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_IRJ_PC_Arqui_NNSS],
    fill: Funct_FillStyle[Symbolyze_Layer_IRJ_PC_Arqui_NNSS],
    zIndex: Funct_ZIndexStyle[Symbolyze_Layer_IRJ_PC_Arqui_NNSS]
  })
};


var vectorSource_I_PC_Arqui_NNSS  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PATR_ARQUIT)
});

global.Layer_IRJ_PC_Arqui_NNSS = new VectorLayer({
  title: 'Layer_IRJ_PC_Arqui_NNSS',
  name: 'F01 ' + Symbolyze_Layer_IRJ_PC_Arqui_NNSS,
  type: 'Capa específica',
  source: vectorSource_I_PC_Arqui_NNSS,
  style: styleFunction_I_PC_Arqui_NNSS
});

var styleFunction_I_PC_Arqui_CAT  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_IRJ_PC_Arqui_CAT],
    fill: Funct_FillStyle[Symbolyze_Layer_IRJ_PC_Arqui_CAT],
    zIndex: Funct_ZIndexStyle[Symbolyze_Layer_IRJ_PC_Arqui_CAT]
  })
};


var vectorSource_I_PC_Arqui_CAT  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PATR_ARQUIT)
});

global.Layer_IRJ_PC_Arqui_CAT = new VectorLayer({
  title: 'Layer_IRJ_PC_Arqui_CAT',
  name: 'F02 ' + Symbolyze_Layer_IRJ_PC_Arqui_CAT,
  type: 'Capa específica',
  source: vectorSource_I_PC_Arqui_CAT,
  style: styleFunction_I_PC_Arqui_CAT
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IRJ_10 = new LayerGroup({
  title:    'Map_IRJ_10',
  name:     'Patrimonio cultural',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_IRJ_PC_Arqui_NNSS,
    Layer_IRJ_PC_Arqui_CAT
  ]
});
////////////////////////////////////////////////////////////////////////
